<template>
  <div
    class="load-bar-slider"
    :class="{ 'load-bar-slider--fixed': fixed, active: active }">
    <div class="load-bar-line"></div>
    <div class="load-bar-break load-bar-1"></div>
    <div class="load-bar-break load-bar-2"></div>
    <div class="load-bar-break load-bar-3"></div>
  </div>
</template>

<script>
import BarLoaderMixin from "@/client/extensions/mixins/baseComponents/loaders/barLoader.js";

export default {
  mixins: [BarLoaderMixin],

  props: {
    theme: {
      type: String,
      default: "lead",
    },
  },
  data() {
    return {
      themeMap: {
        bg: {
          background: "gray-4",
          color: "bg",
        },
      },
    };
  },
  computed: {
    backgroundColor() {
      if (this.themeMap[this.theme]) {
        return `var(--c-${this.themeMap[this.theme].background})`;
      }
      return `var(--c-bg)`;
    },
    lineColor() {
      if (this.themeMap[this.theme]) {
        return `var(--c-${this.themeMap[this.theme].color})`;
      }

      return `var(--c-${this.theme})`;
    },
  },
};
</script>

<style lang="scss" scoped>
.load-bar-slider {
  position: relative;
  width: 100%;
  flex: 100%;
  height: 0;
  display: block;
  opacity: 0;
  pointer-events: none;
  transition: opacity 100ms ease-in;

  &.absolute {
    position: absolute;
  }
  &.active {
    opacity: 1;
    pointer-events: auto;
    height: 3px;
  }

  &--fixed {
    position: fixed;
    top: 0;
    inset-inline-start: 0;
    z-index: 9999;
  }
}

.load-bar-slider .load-bar-line {
  position: absolute;
  background: v-bind(lineColor);
  width: 100%;
  height: 100%;
}

.load-bar-slider .load-bar-break {
  position: absolute;
  background: v-bind(backgroundColor);
  width: 50px;
  height: 3px;
}

.load-bar-slider .load-bar-break.load-bar-1 {
  -webkit-animation: bar-loading 2s infinite;
  -moz-animation: bar-loading 2s infinite;
  -o-animation: bar-loading 2s infinite;
  animation: bar-loading 2s infinite;
}

.load-bar-slider .load-bar-break.load-bar-2 {
  -webkit-animation: bar-loading 2s 0.5s infinite;
  -moz-animation: bar-loading 2s 0.5s infinite;
  -o-animation: bar-loading 2s 0.5s infinite;
  animation: bar-loading 2s 0.5s infinite;
}

.load-bar-slider .load-bar-break.load-bar-3 {
  -webkit-animation: bar-loading 4s 1s infinite;
  -moz-animation: bar-loading 4s 1s infinite;
  -o-animation: bar-loading 4s 1s infinite;
  animation: bar-loading 4s 1s infinite;
}

@keyframes bar-loading {
  from {
    inset-inline-start: 0;
  }
  to {
    inset-inline-start: 100%;
  }
}
</style>
